import React from "react";
import printer from "../assets/images/3D-printer-4.jpg";
import { FlexboxGrid, Button, Panel, Icon } from "rsuite";
import { RouteComponentProps } from "@reach/router";
import { placeholderText } from "../utils/placeholderText";

const LandingPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${printer})`,
          maxWidth: "100%",
          padding: "0 100px",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "70vh",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "50px 0",
            display: "flex",
            flexDirection: "column",
            width: "35vw",
            textAlign: "left",
          }}
        >
          <div style={{ padding: "20px 0" }}>
            <h2
              style={{
                color: "white",
              }}
            >
              OneFactori Makes 3D Print Easy
            </h2>
          </div>
          <div style={{ padding: "20px 0" }}>
            <Button appearance="primary"> Signup</Button>
          </div>
        </div>
      </div>
      <div className="show-grid">
        <div style={{ padding: "50px 0" }}>
          <h2>Why Should You Choose Us?</h2>
        </div>

        <FlexboxGrid style={{ padding: "0 100px" }}>
          <FlexboxGrid.Item colspan={8}>
            <Panel
              header={
                <>
                  <Icon icon="credit-card" size={"2x"} /> <h3>Easy Payment</h3>
                </>
              }
            >
              {placeholderText}
            </Panel>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <Panel
              header={
                <>
                  <Icon icon="comments" size={"2x"} />
                  <h3>Reliable Communication</h3>
                </>
              }
            >
              {placeholderText}
            </Panel>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <Panel
              header={
                <>
                  <Icon icon="peoples" size={"2x"} />
                  <h3>Everything in One Platform</h3>
                </>
              }
            >
              {placeholderText}
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </>
  );
};

export default LandingPage;
