import React from "react";
import "./App.css";
import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import { Header, Footer, Content } from "rsuite";
import AppRouter from "./router/AppRouter";
import FooterMenu from "./components/FooterMenu/FooterMenu";

function App() {
  return (
    <div className="App">
      <Header>
        <HeaderMenu />
      </Header>
      <Content>
        <AppRouter />
      </Content>
      <Footer>
        <FooterMenu />
      </Footer>
    </div>
  );
}

export default App;
