import React from "react";
import { Divider } from "rsuite";

const FooterMenu: React.FunctionComponent = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Divider />
      <p>Developed and Designed by Kei Ohtani</p>
    </div>
  );
};

export default FooterMenu;
