import React from "react";
import coorporate from "../assets/images/coorporate.jpg";
import people from "../assets/images/people.jpg";
import { FlexboxGrid, Col, Panel, Icon, Divider } from "rsuite";
import { RouteComponentProps } from "@reach/router";
import FourPanel from "../components/FourPanel";
import PageInterchangableRow from "../components/PageInterchangableRow";

const AboutPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <div
        style={{
          maxWidth: "100%",
          padding: "0 100px",
          height: "20vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "35vw",
            textAlign: "center",
          }}
        >
          <h2>About Us</h2>
        </div>
      </div>
      <div className="show-grid">
        <PageInterchangableRow
          src={coorporate}
          alt="coorporate"
          title="The Mission"
          content="We are a platform working to gather expertise and tools to enable small & medium enterprises to systematically build hardware products."
        />

        <PageInterchangableRow
          src={people}
          alt="people"
          title="Our Vision"
          content="To be the best platform to develop and launch product for small and medium enterprises."
          imageFirst={true}
        />
        <Divider style={{ margin: "50px" }} />
        <FlexboxGrid justify="space-around">
          <FlexboxGrid.Item colspan={24} componentClass={Col}>
            <h3>OneFactory Ecosystem</h3>
          </FlexboxGrid.Item>
          <FourPanel
            icon="shapes"
            title="The maker"
            content="Who brings life to new hardware products"
          />
          <FourPanel
            icon="creative"
            title="The designer"
            content="Who generates product design"
          />
          <FourPanel
            icon="gears2"
            title="The engineer"
            content="Who adds functionality to design"
          />
          <FourPanel
            icon="cubes"
            title="The manufacturer"
            content="Who makes parts and assembles the final product"
          />
        </FlexboxGrid>
      </div>
    </>
  );
};

export default AboutPage;
