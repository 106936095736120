import React from "react";
import { Router } from "@reach/router";
import LandingPage from "../pages/LandingPage";
import AboutPage from "../pages/AboutPage";

const AppRouter: React.FunctionComponent = () => {
  return (
    <Router>
      <LandingPage path="/" />
      <AboutPage path="/about" />
    </Router>
  );
};

export default AppRouter;
