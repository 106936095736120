import React from "react";
import { FlexboxGrid, Col, Panel } from "rsuite";

export interface PageInterchangableRow {
  src: string;
  alt: string;
  title: string;
  content: string;
  imageFirst?: boolean;
}

const PageInterchangableRow: React.FunctionComponent<PageInterchangableRow> = ({
  src,
  alt,
  title,
  content,
  imageFirst,
}) => {
  const imageComponent = (
    <FlexboxGrid.Item colspan={22} componentClass={Col} md={12}>
      <img src={src} alt={alt} width={800} style={{ maxWidth: "100%" }} />
    </FlexboxGrid.Item>
  );
  return (
    <FlexboxGrid justify="space-around" style={{ paddingBottom: "50px" }}>
      {!imageFirst || imageComponent}
      <FlexboxGrid.Item
        colspan={22}
        componentClass={Col}
        md={10}
        style={{ paddingTop: "50px" }}
      >
        <h3>{title}</h3>
        <Panel>{content}</Panel>
      </FlexboxGrid.Item>
      {imageFirst || imageComponent}
    </FlexboxGrid>
  );
};

export default PageInterchangableRow;
