import React from "react";
import { FlexboxGrid, Col, Panel, Icon, IconProps } from "rsuite";

export interface FourPanelProps {
  icon: IconProps["icon"];
  title: string;
  content: string;
}

const FourPanel: React.FunctionComponent<FourPanelProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <FlexboxGrid.Item
      md={5}
      sm={22}
      componentClass={Col}
      style={{ paddingTop: "50px" }}
    >
      <Panel bordered>
        <Icon icon={icon} size="5x" style={{ marginBottom: "20px" }} />
        <h4>{title}</h4>
        {content}
      </Panel>
    </FlexboxGrid.Item>
  );
};

export default FourPanel;
