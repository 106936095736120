import React from "react";
import { Navbar, Nav } from "rsuite";
import { Link } from "@reach/router";

const HeaderMenu: React.FunctionComponent = () => {
  return (
    <Navbar>
      <Navbar.Header>
        <Link
          to="/"
          style={{
            padding: "18px 20px",
            display: "inline-block",
            fontWeight: 900,
          }}
        >
          OneFactori
        </Link>
      </Navbar.Header>
      <Navbar.Body>
        <Nav>
          <Link to="about">
            <Nav.Item>About Us</Nav.Item>
          </Link>
          <Link to="products">
            <Nav.Item>Products</Nav.Item>
          </Link>
        </Nav>
        <Nav pullRight>
          <Link to="signin">
            <Nav.Item>Signin</Nav.Item>
          </Link>
          <Link to="signup">
            <Nav.Item>Signup</Nav.Item>
          </Link>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

export default HeaderMenu;
